import { render, staticRenderFns } from "./UsasExcavationDetails.vue?vue&type=template&id=07866557&"
import script from "./UsasExcavationDetails.vue?vue&type=script&lang=js&"
export * from "./UsasExcavationDetails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomNavigation } from 'vuetify/lib/components/VBottomNavigation';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBottomNavigation,VBtn,VCard,VCardActions,VCardText,VCardTitle,VCheckbox,VCol,VCombobox,VContainer,VDatePicker,VDivider,VIcon,VMenu,VRow,VSelect,VSpacer,VSwitch,VTextField,VTimePicker,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("N:\\diglogix\\diglogix-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('07866557')) {
      api.createRecord('07866557', component.options)
    } else {
      api.reload('07866557', component.options)
    }
    module.hot.accept("./UsasExcavationDetails.vue?vue&type=template&id=07866557&", function () {
      api.rerender('07866557', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/workflow/ticket/centers/usas/UsasExcavationDetails.vue"
export default component.exports